import React, { useEffect, useState, useRef } from 'react'
import { useSearchParams } from 'react-router-dom';
import Conversation from './Conversation'
import MessageBox from './MessageBox'

const Main = ({ socket }) => {
  const [messages, setMessages] = useState([])
  const [typingWatcherId, setTypingWatcherId] = useState(null)
  const lastMessageRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const mode = searchParams.get('__mode');
  const isDisplayMode = mode && mode === 'display';

  // Fetch recent messages
  useEffect(() => {
    socket.on('connect', () => {
      if (!socket.connected) {
        return;
      }
      const url = `${process.env.REACT_APP_API_URL}/recent?socketId=${socket.id}`;
      fetch(url)
        .then(response => response.json())
        .then(json => setMessages(json))
        .then(() => setTimeout(() => {lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' })}, 1000))
        .catch(console.error);
    });
  });

  // New Message Notification
  useEffect(() => {
    socket.on('message', (data) => {
      if (typingWatcherId) {
        clearTimeout(typingWatcherId);
      }
      setTypingWatcherId(null);
      let newMessages = [...messages, data];
      const messageLength = newMessages.length;

      // keep most recent 100 messages
      if (messageLength > 100) {
        newMessages = newMessages.slice((messageLength - 100), messageLength);
      }

      setMessages(newMessages);
    });
  }, [socket, messages, typingWatcherId]);

  // Typing Notification
  useEffect(() => {
    socket.on('typing', ({ socketId }) => {
      if (socketId === socket.id) {
        return;
      }

      if (typingWatcherId) {
        clearTimeout(typingWatcherId);
      }

      const timeoutId = setTimeout(() => setTypingWatcherId(null), process.env.REACT_APP_TYPE_AHEAD_TIMEOUT);
      setTypingWatcherId(timeoutId);
    });
  }, [socket, typingWatcherId]);

  // Scroll to bottom every time messages change 
  useEffect(() => {
    if (isDisplayMode) {
      lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, typingWatcherId]);


  return (
    <div className='flex flex-col justify-between items-center w-full sm:w-[70%] h-[calc(100dvh)] bg-black text-white'>
      <Conversation messages={messages} socket={socket} typingStatus={typingWatcherId} lastMessageRef={lastMessageRef} />
      {!isDisplayMode && <MessageBox socket={socket} />}
    </div>
  );
}

export default Main
