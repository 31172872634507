import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './components/Main';
import socketIO from 'socket.io-client';

const socket = socketIO.connect(process.env.REACT_APP_SERVER);

function App() {
  useEffect(() => {
    document.title = 'Reflections';
  }, []);

  return (
    <BrowserRouter>
        <Routes>
          <Route path='/' element={<Main socket={socket} />}></Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
