import React, { useRef } from 'react'

const Conversation = ({ messages, socket, typingStatus, lastMessageRef }) => {

  const ref = useRef(null)

  const getChatBubble = ({ socketId, text, timestamp, bossMode }) => {
    const date = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' }).format(timestamp);

    if (socketId === socket.id || !!bossMode) {
      return (
        <div className='flex flex-col text-[15px] sm:text-[20px] w-full items-end' key={`${socketId}:${timestamp}`}>
          <span className='text-[11px] pl-4'>{date}</span>
          <div className='flex min-w-[60px] max-w-[70%]'>
            <div className='bg-blue-300 text-black px-5 py-2 rounded-3xl mb-3 sm:mb-5'>{text.split("\n").map((i, key) => {
              return <div key={key}>{i}</div>;
            })}</div>
          </div>
        </div>
      );
    }

    return (
      <div className='flex flex-col text-[15px] sm:text-[20px]' key={`${socketId}:${timestamp}`}>
        <span className='text-[11px] pl-4'>{date}</span>
        <div className='flex min-w-[60px] max-w-[70%]'>
          <div className='bg-white text-black px-5 py-2 rounded-3xl mb-3 sm:mb-5'>{text}</div>
        </div>
      </div>
    );
  };

  const getTypingIndicator = () => {
    return (
      <div className='flex text-[20px] sm:text-[40px] w-full'>
        <div className='dots bg-gray-800 px-3'>
          <span className='one'>.</span>
          <span className='two'>.</span>
          <span className='three'>.</span>
        </div>
      </div>
    );
  };

  const getNoTypingIndicator = () => {
    return (
      <div className='flex text-[20px] sm:text-[40px] w-full'>
        <div className='dots bg-black px-3'>
          <span className='text-black'>.</span>
        </div>
      </div>
    );
  };

  return (
    <div className='flex flex-col p-3 pb-5 mt-5 overflow-y-auto no-scrollbar w-full'>
      {messages.map(message => getChatBubble(message))}

      {typingStatus ? getTypingIndicator() : getNoTypingIndicator()}

      <div ref={lastMessageRef} />
    </div>
  )
}

export default Conversation
