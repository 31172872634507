import React, { useState, useCallback, useRef } from 'react'

const MessageBox = ({ socket }) => {
  const limit = process.env.REACT_APP_MESSAGE_CHAR_LIMIT;

  const inputRef = useRef(null);
  const [message, setMessage] = useState('');

  const setFormattedContent = useCallback((text) => {
    setMessage(text.slice(0, limit));
  }, [limit, setMessage]);

  const handleTyping = () => socket.emit('typing', { socketId: socket.id });

  const hasBossMode = (message) => {
    return !!localStorage.getItem('bossmode')
      || message.includes('toronto')
      || message.includes('love')
      || message.includes('friend');
  };

  const handleSendMessage = (e) => {
    e.preventDefault();

    const msgSize = message.trim().length;

    if (msgSize === 0) {
      return;
    }

    let payload = {
      text: message.trim(),
      socketId: socket.id,
      timestamp: Date.now(),
    };

    if (hasBossMode(message)) {
      localStorage.setItem('bossmode', 'true');
      payload.bossMode = true;
    }

    socket.emit('message', payload);
    setMessage('');
    inputRef.current.blur();
  }

  const showAbout = () => {
    window.open('https://fromwaves.com', '_blank');
  };

  return (
    <div className='flex justify-center items-center w-full mt-2'>
      <form className='flex items-center justify-between pb-4 p-2 w-full' onSubmit={handleSendMessage}>
        <div className='flex flex-col w-full'>

          {/* Message Bar */}
          <div className='flex mb-2'>
            <div className='absolute pl-4 text-[11px]' onClick={showAbout}>Fromwaves.</div>
            <div className='flex w-full items-end justify-end text-[11px]'>
              <p className='pr-3 pb-1'>{message.length}/{limit}</p>
            </div>
          </div>

          <div className='flex content-center'>
            {/* Input field */}
            <div className='flex w-full rounded-full border-white border-2 p-1 items-center mr-2'>
              <input
                ref={inputRef}
                type='text'
                placeholder='Who are you today?'
                className='rounded-full w-full p-2 bg-black outline-0'
                value={message}
                onChange={e => setFormattedContent(e.target.value)}
                onKeyDown={handleTyping}
              />
            </div>

            {/* Send button */}
            <div className='flex flex-col justify-center'>
              <button onClick={handleSendMessage} className="w-12 h-12 rounded-full 
                  inline-flex items-center justify-center 
                  bg-white text-gray-700 text-xl font-bold">
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default MessageBox
